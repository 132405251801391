const kamusTema = {
  1: "jenaka",
  2: "agama",
  3: "nasihat",
  4: "budi",
  // 5: "kasih sayang",
  // 6: "adat",
  // 7: "kiasan",
  // 8: "perantauan",
  // 9: "peribahasa",
  10: "buat warga emas",
  // 11: "kepahlawanan",
  // 12: "perniagaan",
  13: "bahasa jiwa bangsa",
  // 14: "berbalas pantun",
  15: "pembuka acara",
  16: "bacaan doa",
  17: "jemput makan",
  18: "ucapan alu-aluan",
  19: "jemputan ucapan",
  20: "ucapan perasmian",
  21: "akhir majlis",
  22: "merisik",
  // 23: "nirtentu",
  24: "Ayuh Membaca - Untuk Kempen Nilam Sekolah",
};

export default kamusTema;
