import React from "react";

function LogMasuk() {
    return (
        <main>
            <h1>LogMasuk</h1>
        </main>
    )
}

export default LogMasuk;