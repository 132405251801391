import "./Sasaran.css";

function Sasaran() {
  return (
    <main className="App-main">
      <div>
        <span className="animate__animated animate__fadeIn">
          <big id="sasaran">
            <b>1 juta</b>
          </big>{" "}
          <p>pantun dalam pangkalan data</p>
        </span>
      </div>
    </main>
  );
}

export default Sasaran;
